<template>
	<v-card class="wr_grey_1 elevation-4">
		<!-- Show Tabs (Only when pads have been created) -->
		<Tabs v-if="setup_done"></Tabs>

		<!-- Else show loader -->
		<v-card-text v-else>
			<Loading></Loading>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { referenceManagerMixin } from "@/mixins/researchPaper/referenceManagerMixin.js";
import { researchPaperPadManagerMixin } from "@/mixins/researchPaper/padManagerMixin.js";
import Tabs from "@/components/research/modules/write/stages/researchPaper/tabs/Index.vue";
import Loading from "@/components/plugins/Loading";

export default {
	name: "ResearchPaper",

	mixins: [referenceManagerMixin, researchPaperPadManagerMixin],

	components: {
		Tabs,
		Loading,
	},

	computed: {
		...mapState({
			template: (state) => state.researchPaper.template,
			sources: (state) => state.source.list,
		}),

		...mapGetters({
			userProfile: "user/getProfile",
		}),
	},

	mounted() {
		this.init();
	},

	methods: {
		async init() {
			try {
				this.$loader.start();

				// Prepare pad for chapters if not exists.
				await this.manageChaptersAndRespectivePads();

				// get all sources
				await this.$store.dispatch("source/list");

				// Prepare reference from sources
				let response = await this.prepareReferenceFormat(this.sources);

				// Save to etherpad (even if empty array)
				await this.$store.dispatch("etherpad/saveReferences", {
					user_id: this.userProfile.id,
					data: response,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
